import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box, CssBaseline, Paper, TextField, Select, MenuItem, InputLabel, FormControl, Fade, Grow } from '@mui/material';
import NavigationBar from './NavigationBar';
import { useAuth } from '../context/AuthContext';
import { MessageSquare, Send, ThumbsUp } from 'lucide-react';
import './FeedBack.css';
import GoogleLogin from '../GoogleLogin';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00256C',
    },
    secondary: {
      main: '#FF6F61',
    },
  },
});

function FeedbackForm() {
  const [feedbackType, setFeedbackType] = useState('');
  const [feedbackText, setFeedbackText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { isAuthenticated, userInfo } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email: userInfo.email,
          type: feedbackType, 
          text: feedbackText 
        }),
      });
      if (response.ok) {
        setIsSubmitted(true);
        setFeedbackType('');
        setFeedbackText('');
      } else {
        alert('피드백 제출 중 오류가 발생했습니다.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('피드백 제출 중 오류가 발생했습니다.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isAuthenticated) {
    return <GoogleLogin />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className="feedback-container gradient-background">
        <NavigationBar />
        <Fade in={true} timeout={1000}>
          <Box className="feedback-content">
            <Paper elevation={3} className="main-paper">
              {!isSubmitted ? (
                <Grow in={true} timeout={1000}>
                  <Box>
                    <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: theme.palette.primary.main }}>
                      <MessageSquare sx={{ mr: 2, fontSize: 40 }} />
                      피드백 보내기
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom align="center" sx={{ mb: 3 }}>
                      여러분의 의견은 저희 크루크루에게 매우 소중합니다.
                    </Typography>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                      <InputLabel id="feedback-type-label">피드백 유형</InputLabel>
                      <Select
                        labelId="feedback-type-label"
                        value={feedbackType}
                        onChange={(e) => setFeedbackType(e.target.value)}
                        label="피드백 유형"
                      >
                        <MenuItem value="inquiry">문의사항</MenuItem>
                        <MenuItem value="error">오류사항</MenuItem>
                        <MenuItem value="improvement">개선사항</MenuItem>
                        <MenuItem value="other">기타 피드백</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label="피드백 내용"
                      value={feedbackText}
                      onChange={(e) => setFeedbackText(e.target.value)}
                      sx={{ mb: 3 }}
                    />
                    <Button
                      variant="contained"
                      startIcon={<Send />}
                      onClick={handleSubmit}
                      disabled={!feedbackType || !feedbackText || isSubmitting}
                      fullWidth
                      sx={{ py: 1.5, fontSize: '1.1rem' }}
                    >
                      {isSubmitting ? '제출 중...' : '피드백 제출'}
                    </Button>
                  </Box>
                </Grow>
              ) : (
                <Grow in={true} timeout={1000}>
                  <Box sx={{ textAlign: 'center' }}>
                    <ThumbsUp sx={{ fontSize: 80, color: theme.palette.primary.main, mb: 2 }} />
                    <Typography variant="h4" gutterBottom color="primary">
                      감사합니다!
                    </Typography>
                    <Typography variant="subtitle1">
                      소중한 의견 감사합니다. 더 나은 서비스를 위해 노력하겠습니다.
                    </Typography>
                  </Box>
                </Grow>
              )}
            </Paper>
          </Box>
        </Fade>
      </Box>
    </ThemeProvider>
  );
  }
  
  export default FeedbackForm;