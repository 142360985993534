import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  ThemeProvider,
  createTheme,
  CircularProgress,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Fade,
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress
  
} from '@mui/material';
import { CalendarToday, ImportContacts, CheckCircleOutline, ErrorOutline, InfoOutlined } from '@mui/icons-material';
import NavigationBar from './NavigationBar';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';


const theme = createTheme({
  palette: {
    primary: { main: '#FF6F61' },
    secondary: { main: '#4A90E2' },
  },
});

const steps = ['VPN 연결', '구글 캘린더 인증', '크루월드 로그인', '일정 가져오기'];

const ScheduleParsing = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [statusList, setStatusList] = useState([]);
  const [tokenClient, setTokenClient] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [vpnConnected, setVpnConnected] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isError, setIsError] = useState(false);
  const [completedTasks, setCompletedTasks] = useState(new Set());
  const [lastProgress, setLastProgress] = useState(0);
  const [fetchCurrentMonth, setFetchCurrentMonth] = useState(true);
  const [fetchNextMonth, setFetchNextMonth] = useState(false);


  const { 
    isAuthenticated, 
    iscalendarAuth, 
    setCalendarAuth, 
    setAccessToken, 
    setCalendarId,
    calendarId,
    accessToken
  } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
      return;
    }

    checkVpnConnection();

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.onload = initializeGoogleSignIn;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [isAuthenticated, navigate]);

  const checkVpnConnection = async () => {
    try {
      const response = await fetch('https://creworld.flyasiana.com/crw/websquare/websquare.html?w2xPath=/crw/ux/login/login.xml', {
        method: 'HEAD',
        mode: 'no-cors'
      });
      setVpnConnected(true);
      setActiveStep(1);
      setStatusList(prev => [...prev, { message: 'VPN 연결 확인됨', type: 'success' }]);
    } catch (error) {
      console.error('VPN connection check failed:', error);
      setVpnConnected(false);
      setSnackbar({ open: true, message: 'VPN 연결을 확인할 수 없습니다. VPN에 연결되어 있는지 확인해주세요.', severity: 'error' });
      setStatusList(prev => [...prev, { message: 'VPN 연결 실패', type: 'error' }]);
    }
  };

  const initializeGoogleSignIn = () => {
    try {
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/calendar',
        callback: (response) => {
          setAccessToken(response.access_token);
          fetchCalendarId(response.access_token);
          setCalendarAuth(true);
          setActiveStep(2);
          setStatusList(prev => [...prev, { message: '구글 캘린더 인증 완료', type: 'success' }]);
        },
      });
      setTokenClient(client);
    } catch (error) {
      console.error('Error initializing Google Sign-In:', error);
      setSnackbar({ open: true, message: '구글 로그인 초기화 중 오류가 발생했습니다.', severity: 'error' });
      setStatusList(prev => [...prev, { message: '구글 로그인 초기화 실패', type: 'error' }]);
    }
  };

  const fetchCalendarId = async (token) => {
    try {
      const response = await fetch('https://www.googleapis.com/calendar/v3/users/me/calendarList', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const data = await response.json();
      const primaryCalendar = data.items.find(calendar => calendar.primary) || data.items[0];
      if (primaryCalendar) {
        setCalendarId(primaryCalendar.id);
        setStatusList(prev => [...prev, { message: '캘린더 ID 설정 완료', type: 'success' }]);
      } else {
        throw new Error('캘린더를 찾을 수 없습니다.');
      }
    } catch (error) {
      console.error('Error fetching calendar list:', error);
      setSnackbar({ open: true, message: '캘린더 정보를 가져오는 중 오류가 발생했습니다.', severity: 'error' });
      setStatusList(prev => [...prev, { message: '캘린더 정보 가져오기 실패', type: 'error' }]);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!fetchCurrentMonth && !fetchNextMonth) {
      setSnackbar({ open: true, message: '최소한 한 달의 스케줄을 선택해주세요.', severity: 'warning' });
      return;
    }

    setLoading(true);
    setActiveStep(2);
    setStatusList([]);
    setProgress(0);
    setLastProgress(0);
    setIsCompleted(false);
    setIsError(false);
    setCompletedTasks(new Set());

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/creworld/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          username, 
          password, 
          calendarId, 
          accessToken,
          fetchCurrentMonth,
          fetchNextMonth
        }),
        mode: 'cors'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        
        const lines = decoder.decode(value).split('\n');
        for (const line of lines) {
          if (line.trim()) {
            try {
              const data = JSON.parse(line);
              updateStatusList(data);

              if (data.progress === 100) {
                setSnackbar({ open: true, message: '일정 가져오기 완료!', severity: 'success' });
                setIsCompleted(true);
                setActiveStep(4);
              } else if (data.progress === -1) {
                setSnackbar({ open: true, message: data.status, severity: 'error' });
                setIsError(true);
                setActiveStep(2);
              } else if (data.status.includes("로그인 성공")) {
                setActiveStep(3);
              }
            } catch (parseError) {
              console.error('JSON 파싱 오류:', parseError);
              updateStatusList({ status: '데이터 처리 중 오류가 발생했습니다.', progress: -1 });
              setIsError(true);
            }
          }
        }
      }
    } catch (error) {
      console.error('오류 발생:', error);
      setSnackbar({ open: true, message: error.message || '서버와의 통신 중 오류가 발생했습니다.', severity: 'error' });
      updateStatusList({ status: error.message || '서버와의 통신 중 오류가 발생했습니다.', progress: -1 });
      setIsError(true);
      setActiveStep(2);
    } finally {
      setLoading(false);
    }
  };

  const getStatusType = (progress) => {
    if (progress === 100) return 'success';
    if (progress === -1) return 'error';
    return 'info';
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };
  const updateStatusList = (data) => {
    setStatusList(prev => {
      const newList = [...prev];
      const existingIndex = newList.findIndex(item => item.message === data.status);
      
      if (existingIndex !== -1) {
        newList[existingIndex] = { ...newList[existingIndex], type: getStatusType(data.progress) };
      } else {
        newList.push({ message: data.status, type: getStatusType(data.progress) });
      }
  
      // 프로그레스가 증가했을 때 이전 단계들을 완료 처리
      if (data.progress > lastProgress) {
        newList.forEach((item, index) => {
          if (index < newList.length - 1) {
            item.type = 'success';
          }
        });
        setLastProgress(data.progress);
      }
  
      setProgress(data.progress);
  
      return newList;
    });
  
    if (data.progress === 100 || data.status.includes("완료")) {
      setCompletedTasks(prev => new Set(prev).add(data.status));
    }
  };

  const getStatusIcon = (type) => {
    switch (type) {
      case 'success':
        return <CheckCircleOutline color="success" />;
      case 'error':
        return <ErrorOutline color="error" />;
      case 'info':
        return <InfoOutlined color="info" />;
      default:
        return <CircularProgress size={20} />;
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <NavigationBar />
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
          <Paper elevation={3} sx={{ width: '100%', maxWidth: '800px', p: 4, backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
            <Typography variant="h4" gutterBottom align="center">
              크루월드 스케쥴 가져오기
            </Typography>
            
            <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Box sx={{ mb: 4, p: 2, backgroundColor: 'rgba(74, 144, 226, 0.1)', borderRadius: '4px' }}>
              <Typography variant="subtitle1" gutterBottom>
                사용 방법 및 주의사항
              </Typography>
              <Typography variant="body2" component="ol" sx={{ pl: 2 }}>
                <li>회사 VPN에 먼저 접속해주세요.</li>
                <li>'구글 캘린더 권한 허용' 버튼을 클릭하여 권한을 허용해주세요.</li>
                <li>크루월드 아이디와 비밀번호를 입력해주세요.</li>
                <li>아이디와 비밀번호는 안전하게 처리되며, 저장되지 않습니다.</li>
                <li>'일정 가져오기' 버튼을 클릭하면 자동으로 일정을 가져옵니다.</li>
                <li>일정 가져오기는 2~3분 정도 소요될 수 있습니다.</li>
                <li>구글 캘린더에 일정이 반영되는 데 시간이 걸릴 수 있으니, 5~10분 후에 확인해주세요.</li>
              </Typography>
            </Box>

            <Box component="form" onSubmit={handleLogin} noValidate>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="크루월드 아이디"
                name="username"
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                disabled={loading || !vpnConnected}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="비밀번호"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={loading || !vpnConnected}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fetchCurrentMonth}
                      onChange={(e) => setFetchCurrentMonth(e.target.checked)}
                      disabled={loading}
                    />
                  }
                  label="이번 달 스케줄 가져오기"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fetchNextMonth}
                      onChange={(e) => setFetchNextMonth(e.target.checked)}
                      disabled={loading}
                    />
                  }
                  label="다음 달 스케줄 가져오기"
                />
              </FormGroup>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                startIcon={<CalendarToday />}
                onClick={() => tokenClient?.requestAccessToken()}
                disabled={iscalendarAuth || loading || !vpnConnected}
                sx={{ mt: 2 }}
              >
                구글 캘린더 권한 허용
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                startIcon={loading ? <CircularProgress size={24} color="inherit" /> : <ImportContacts />}
                sx={{ mt: 2 }}
                disabled={loading || !iscalendarAuth || !vpnConnected}
              >
                {loading ? '일정 가져오는 중...' : '크루월드에서 일정 가져오기'}
              </Button>
            </Box>

            {loading && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <LinearProgress variant="determinate" value={progress} />
                <Typography variant="body2" color="text.secondary" align="center">
                  {`${Math.round(progress)}%`}
                </Typography>
              </Box>
            )}

            {statusList.length > 0 && (
              <Fade in={true}>
                <Box sx={{ mt: 4, maxHeight: 300, overflow: 'auto' }}>
                  <List>
                    {statusList.map((status, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          {getStatusIcon(status.type)}
                        </ListItemIcon>
                        <ListItemText primary={status.message} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Fade>
            )}

            {isCompleted && !isError && (
              <Fade in={true}>
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                  <Typography variant="h6" gutterBottom>
                    일정 가져오기 완료!
                  </Typography>
                  <Typography variant="body1">
                    구글 캘린더에서 일정을 확인해주세요. 반영되는 데 약간의 시간이 걸릴 수 있습니다.
                  </Typography>
                </Box>
              </Fade>
            )}
            {isError && (
              <Fade in={true}>
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                  <Typography variant="h6" gutterBottom color="error">
                    오류 발생
                  </Typography>
                  <Typography variant="body1">
                    일정 가져오기 중 문제가 발생했습니다. 다시 시도해 주세요.
                  </Typography>
                </Box>
              </Fade>
            )}
          </Paper>
        </Box>
      </Box>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};
export default ScheduleParsing;