import React from 'react';
import { Box, CssBaseline, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavigationBar from './NavigationBar';
import './SharePlace.css'; // CSS 파일을 import하여 스타일 적용

const theme = createTheme();

const SharePlace = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <NavigationBar />
        <CssBaseline />
        <div className="share-place-container">
          <div className="share-place-content">
            <Typography variant="h4" component="h1" gutterBottom className="share-place-title">
              맛집 공유
            </Typography>
            <Typography variant="body1">
              여기에 맛집 공유에 대한 내용을 추가하세요.
            </Typography>
          </div>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default SharePlace;
