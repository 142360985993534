import React, { useRef, useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import './ScheduleImage.css';
import { Snackbar, Box, CssBaseline, CircularProgress, Alert, Paper, Modal } from '@mui/material';
import NavigationBar from './NavigationBar';
import { useAuth } from '../context/AuthContext';
import { CloudUpload, CalendarToday, Image } from '@mui/icons-material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00256C',
    },
    secondary: {
      main: '#4A90E2',
    },
  },
});

function ScheduleImage() {
  const { 
    isAuthenticated, 
    iscalendarAuth, 
    setCalendarAuth, 
    accessToken, 
    setAccessToken, 
    calendarId, 
    setCalendarId, 
    userInfo
  } = useAuth();
  const fileInputRef = useRef(null);
  const dropZoneRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [tokenClient, setTokenClient] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
      return;
    }
    
    if (location.state) {
      if (location.state.calendar_id) {
        setCalendarId(location.state.calendar_id);
      }
      if (location.state.access_token) {
        setAccessToken(location.state.access_token);
      }
    }
 
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.onload = initializeGoogleSignIn;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [location]);

  useEffect(() => {
    const dropZone = dropZoneRef.current;
    
    const handleDragOver = (e) => {
      e.preventDefault();
      setIsDragging(true);
    };
    
    const handleDragLeave = () => {
      setIsDragging(false);
    };
    
    const handleDrop = (e) => {
      e.preventDefault();
      setIsDragging(false);
      if (e.dataTransfer.files.length) {
        handleFiles(e.dataTransfer.files);
      }
    };

    dropZone.addEventListener('dragover', handleDragOver);
    dropZone.addEventListener('dragleave', handleDragLeave);
    dropZone.addEventListener('drop', handleDrop);

    return () => {
      dropZone.removeEventListener('dragover', handleDragOver);
      dropZone.removeEventListener('dragleave', handleDragLeave);
      dropZone.removeEventListener('drop', handleDrop);
    };
  }, []);

  const initializeGoogleSignIn = () => {
    try {
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/calendar',
        callback: (response) => {
          setAccessToken(response.access_token);
          fetchCalendarId(response.access_token);
          setCalendarAuth(true);
        },
      });
      setTokenClient(client);
    } catch (error) {
      console.error('Error initializing Google Sign-In:', error);
    }
  };

  const fetchCalendarId = async (token) => {
    try {
      const response = await fetch('https://www.googleapis.com/calendar/v3/users/me/calendarList', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.items && data.items.length > 0) {
        const primaryCalendar = data.items.find(calendar => calendar.primary);
        if (primaryCalendar) {
          setCalendarId(primaryCalendar.id);
        } else {
          const firstCalendarId = data.items[0].id;
          setCalendarId(firstCalendarId);
        }
      } else {
        console.error('No calendars found.');
      }
    } catch (error) {
      console.error('Error fetching calendar list:', error);
    }
  };

  const handleFiles = (files) => {
    if (files.length > 0) {
      handleUpload(accessToken, files[0]);
    }
  };

  const handleFileChange = (event) => {
    handleFiles(event.target.files);
  };

  const handleUpload = async (token, file) => {
    setIsLoading(true);
    
    const isImageFile = (file) => {
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      return allowedExtensions.includes(fileExtension);
    };
    
    if (file && calendarId && token) {
      if (!isImageFile(file)) {
        setSnackbarMessage('이미지 파일(.jpg, .jpeg, .png, .gif)만 업로드 가능합니다.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setIsLoading(false);
        return;
      }
  
      const formData = new FormData();
      formData.append('file', file);
      formData.append('calendar_id', calendarId);
      formData.append('access_token', token);
      formData.append('email', userInfo.email);
  
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/upload-image`, {
          method: 'POST',
          body: formData,
        });
        const data = await response.json();
        if (response.ok) {
          setSnackbarMessage('이미지 업로드 및 스케줄 처리가 완료되었습니다.');
          setSnackbarSeverity('success');
        } else {
          throw new Error(data.message || '이미지 업로드 실패');
        }
      } catch (error) {
        setSnackbarMessage(`오류 발생: ${error.message}`);
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
        setIsLoading(false);
        fileInputRef.current.value = '';
      }
    } else {
      setSnackbarMessage('이미지가 선택되지 않았거나 필요한 정보가 없습니다.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className="schedule-image-container gradient-background">
        <NavigationBar />
        <Box className="content-wrapper">
          <Paper elevation={3} className="main-paper">
            <Typography variant="h4" className="title" gutterBottom>
              스케줄 이미지 업로드
            </Typography>
            <Box sx={{ mb: 2, p: 2, backgroundColor: 'rgba(74, 144, 226, 0.1)', borderRadius: '4px' }}>
              <Typography variant="subtitle2" gutterBottom>
                사용법 및 주의사항
              </Typography>
              <Typography variant="body2" component="ol" sx={{ pl: 2 }}>
                <li>구글 캘린더 권한을 먼저 허용해주세요.</li>
                <li>아래의 예시 이미지와 같은 형식의 스케줄표 이미지를 업로드해주세요.</li>
                <li>스케줄표가 잘 보이도록 이미지를 찍어주세요.</li>
                <li>이미지 화질이 낮으면 정확도가 떨어질 수 있습니다.</li>
                <li>정확도는 이미지 품질에 따라 달라집니다.</li>
                <li>이미지 업로드 후 처리 시간이 소요될 수 있습니다.</li>
                <li>업로드 성공 후 구글 캘린더 동기화에 시간이 걸릴 수 있습니다.</li>
                <li>구글 캘린더에 충분한 시간(5~10분)을 가지고 확인해주세요.</li>
              </Typography>
            </Box>
            {/* 스케줄표 예시 보기 버튼 */}
             {/* 스케줄표 예시 보기 버튼 */}
             <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
              <Button variant="outlined" onClick={handleOpen} sx={{ color: '#00256C', borderColor: '#00256C' }}>
                스케줄표 예시 보기
              </Button>
            </Box>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="예시 이미지"
              aria-describedby="예시 스케줄표 전체 보기"
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
                onClick={handleClose} // 모달 외부 클릭 시 닫기
              >
                <img
                  src="./ScehduleStandard.png"
                  alt="예시 스케줄표"
                  style={{ maxWidth: '100%', height: 'auto', cursor: 'pointer' }}
                  onClick={handleClose} // 이미지 클릭 시 닫기
                />
              </Box>
            </Modal>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Button
                variant="contained"
                startIcon={<CalendarToday />}
                onClick={() => {
                  if (tokenClient) {
                    tokenClient.requestAccessToken();
                  }
                }}
                disabled={iscalendarAuth}
                aria-label="구글 캘린더 권한 허용"
                className="action-button primary-button"
              >
                구글 캘린더 권한 허용
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
              />
              <Box
                ref={dropZoneRef}
                className={`drag-drop-zone ${isDragging ? 'dragging' : ''}`}
                onClick={() => fileInputRef.current.click()}
              >
                <Typography>
                  {isDragging ? '이미지를 놓아주세요' : '이미지를 드래그하여 놓거나 클릭하여 선택하세요'}
                </Typography>
              </Box>
            </Box>
            {isLoading && (
              <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress size={24} sx={{ mr: 1 }} />
                <Typography>이미지 처리 및 구글 캘린더 동기화 진행중...</Typography>
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default ScheduleImage;