import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './NavigationBar.css';

const NavigationBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleLogoClick = () => {
        navigate('/');
        closeMenu();
    };

    useEffect(() => {
        closeMenu();
    }, [location]);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (isMenuOpen && !event.target.closest('.navigation-bar-menu') && !event.target.closest('.navigation-bar-menu-icon')) {
            setIsMenuOpen(false);
          }
        };
      
        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, [isMenuOpen]);

    return (
        <nav className="navigation-bar">
            <div className="navigation-bar-content">
                <div className="navigation-bar-logo" onClick={handleLogoClick}>CREWCREW</div>
                <button className="navigation-bar-menu-icon" onClick={toggleMenu} aria-label="Toggle menu">
                    {isMenuOpen ? '✕' : '☰'}
                </button>
                <div className={`navigation-bar-menu ${isMenuOpen ? 'active' : ''}`}>
                    <Link to="/" className="navigation-bar-menu-item" onClick={closeMenu}>Home</Link>
                    <Link to="/ScheduleMain" className="navigation-bar-menu-item" onClick={closeMenu}>Schedule</Link>
                    <Link to="/AppVision" className="navigation-bar-menu-item" onClick={closeMenu}>Vision</Link>
                    <Link to="/FeedBack" className="navigation-bar-menu-item" onClick={closeMenu}>Contact us</Link>
                </div>
            </div>
        </nav>
    );
};

export default NavigationBar;