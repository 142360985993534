import React, { createContext, useContext, useState, useCallback } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [iscalendarAuth, setCalendarAuth] = useState(false);
    const [isExcelUploadAuthorized, setExcelUploadAuthorized] = useState(false);
    const [accessToken, setAccessToken] = useState(null);
    const [calendarId, setCalendarId] = useState(null);

    // userInfo를 설정하고 동시에 관리자 여부를 확인하는 함수
    const setUserInfoAndCheckAdmin = useCallback((info) => {
        setUserInfo(info);
        // 여기서 관리자 여부를 확인합니다. 
        // 예를 들어, info.role === 'admin' 또는 info.isAdmin === true 등으로 확인할 수 있습니다.
        setIsAdmin(info?.is_admin === true || info?.email === 'sunmo0602@gmail.com');
    }, []);

    // 로그아웃 함수
    const logout = useCallback(() => {
        setIsAuthenticated(false);
        setUserInfo(null);
        setIsAdmin(false);
        setCalendarAuth(false);
        setExcelUploadAuthorized(false);
        setAccessToken(null);
        setCalendarId(null);
    }, []);

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                setIsAuthenticated,
                userInfo,
                setUserInfo: setUserInfoAndCheckAdmin,
                isAdmin,
                iscalendarAuth,
                setCalendarAuth,
                isExcelUploadAuthorized,
                setExcelUploadAuthorized,
                accessToken,
                setAccessToken,
                calendarId,
                setCalendarId,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};